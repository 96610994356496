import $ from 'jquery';
import { toast } from 'react-toastify';
import { BASE_URL_AUTH } from 'assets/js/baseUrl';

export const AUTH__SAVE_TOKENS = 'AUTH__SAVE_TOKENS';
export function saveTokens(tokens) {
    return {
        type: AUTH__SAVE_TOKENS,
        accessToken: tokens.accessToken,
        refreshToken: tokens.refreshToken,
        expiresIn: tokens.expiresIn
    }
}

export const AUTH__SAVE_WORKPLACE_DATA_FROM_TOKEN = 'AUTH__SAVE_WORKPLACE_DATA_FROM_TOKEN';
export function saveDataFromToken(accessToken) {
    let accessTokenData = JSON.parse(atob(accessToken.split('.')[1]));
    return {
        type: AUTH__SAVE_WORKPLACE_DATA_FROM_TOKEN,
        workplaceNumber: +accessTokenData.workplaceData.workplace_number,
        workPointId: accessTokenData.workplaceData.work_point_id,
    }
}

export function checkToken() {
    return () => {}
}

export function logout() {
    localStorage.clear();
    window.location.replace('/');
}
