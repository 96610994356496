import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { toast } from 'react-toastify';
import $ from 'jquery';

import Input from 'components/Input/Input';
import Button from 'components/Button/Button';
import { saveTokens, saveDataFromToken } from 'redux/auth/action';
import { BASE_URL_AUTH } from 'assets/js/baseUrl';
import './Login.scss';

const Login = () => {
	const dispatch = useDispatch();
	const { accessToken } = useSelector(state => state.authReducer);

	const [login, setLogin] = useState();
	const [password, setPassword] = useState()

	useEffect(() => {
		document.title = 'Вход монитора клиента на рабочем месте | TOTO';
	}, [])

	const loginFunction = (event) => {
		event.preventDefault();

		if (login.length > 4 && password.length > 4) {
			$.ajax({
				type: 'POST',
				async: false,
				url: BASE_URL_AUTH + '/workplace_screen/login',
				dataType: 'json',
				data: 'DATA=' + JSON.stringify({ login, password }),
				success: data => {
					dispatch(saveTokens(data));
					dispatch(saveDataFromToken(data.accessToken));
				},
				error: error => {
					toast.error('Неправильный логин или пароль! \n Попробуйте еще раз!');
				}
			});
		}
	}

	if (accessToken) {
		return <Redirect to='connection' />
	}

	return (
		<div className='login'>
			<div className='login_block'>
				<form onSubmit={loginFunction} action=''>
					<legend>Введите данные для входа:</legend>
					<Input
						callBack={setLogin}
						id='login'
						type='text'
						placeholder='Логин' />
					<Input
						callBack={setPassword}
						id='password'
						type='password'
						placeholder='Пароль' />
					<Button>Войти</Button>
				</form>
			</div>
		</div>
	);
}

export default Login
