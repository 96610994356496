const AUTH_VERSION = 'v1';
const API_VERSION = 'v1';

let BASE_URL_AUDIO = 'https://api.totoopen.by/audio/';
let BASE_URL_AUTH = 'http://totoTotoAuthApi/public/api/' + AUTH_VERSION;
let WS_URL = 'wss://arcane-inlet-91525.herokuapp.com/api';
let BASE_URL_API = 'http://totoTotoMainApi/public/api/' + API_VERSION;

if (process.env.NODE_ENV === 'production') {
    BASE_URL_AUTH = 'https://auth.totoopen.by/public/api/' + AUTH_VERSION;
    BASE_URL_API = 'https://api.totoopen.by/public/api/' + API_VERSION;
    WS_URL = 'wss://arcane-inlet-91525.herokuapp.com/api';
}

export { BASE_URL_AUTH, BASE_URL_API, BASE_URL_AUDIO, WS_URL };