import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { getConnectionAndDirection, getDataForVideo } from 'redux/app/action';
import Stopwatch from 'components/Stopwatch/Stopwatch';
import './Video.scss';

const Video = () => {
	const dispatch = useDispatch();
	const { workplaceNumber } = useSelector(state => state.authReducer);
	const { masterData, customerData, workDirection, videoList } = useSelector(state => state.appReducer);

	useEffect(() => {
		document.title = 'Видео для клиента | TOTO';
		dispatch(getDataForVideo());
		const intervalId = setInterval(() => dispatch(getConnectionAndDirection()), 8000)
		return () => clearInterval(intervalId);
	}, [])

	let date = useMemo(() => Date.now(), []);

	let videoSrc;

	switch (workDirection) {
		case 1:
			if (!customerData.length) {
				videoSrc = videoList['default'];
			} else {
				videoSrc = videoList[customerData.gender][customerData.age]
			}
			break;

		case 2:
			return (
				<Redirect to='/connection' />
			)

		case 3:
			videoSrc = videoList['cartoons']['3'];
			break;

		case 4:
			videoSrc = videoList['cartoons']['4'];
			break;

		case 5:
			videoSrc = videoList['cartoons']['5'];
			break;

		default:
			break;
	}

	return (
		<div id='video'>

			<div className='top_infoblock'>
				<div class='numscreen'>{workplaceNumber}</div>
				<div class='time'><Stopwatch /></div>
			</div>

			<video autoPlay src={`https://api.totoopen.by/video/${videoSrc}?v=${date}`} muted controls width='100%' />

			<div className='bottom_infoblock'>
				Для вас старается мастер {masterData.name}. Опыт работы {masterData.experience}!
			</div>

		</div>
	);
}

export default Video
