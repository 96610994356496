import {
	AUTH__SAVE_TOKENS,
	AUTH__SAVE_WORKPLACE_DATA_FROM_TOKEN,
} from './action';

const initialState = {
	login: localStorage.login || false,
	accessToken: localStorage.accessToken || '',
	refreshToken: localStorage.refreshToken || '',
	expiresIn: +localStorage.expiresIn || '',

	workplaceNumber: localStorage.accessToken ? +JSON.parse(atob(localStorage.accessToken.split('.')[1])).workplaceData.workplace_number : null,
	workPointId: localStorage.accessToken ? JSON.parse(atob(localStorage.accessToken.split('.')[1])).workplaceData.work_point_id : null,

	alertWindowStatus: false,
	alertWindowMsg: '',

	freshTokenPromise: '',
}

const authReducer = (state = initialState, action) => {
	switch (action.type) {

		case 'REFRESHING_TOKEN':
			return { ...state, freshTokenPromise: action.freshTokenPromise }

		case 'DONE_REFRESHING_TOKEN':
			return { ...state, freshTokenPromise: '' }

		case AUTH__SAVE_TOKENS:
			localStorage.setItem('accessToken', action.accessToken);
			localStorage.setItem('refreshToken', action.refreshToken);
			localStorage.setItem('expiresIn', action.expiresIn);
			localStorage.setItem('login', true);
			return {
				...state,
				accessToken: action.accessToken,
				refreshToken: action.refreshToken,
				expiresIn: action.expiresIn,
				login: true
			}

		case AUTH__SAVE_WORKPLACE_DATA_FROM_TOKEN:
			return {
				...state,
				workplaceNumber: action.workplaceNumber,
				workPointId: action.workPointId
			}

		default:
			return state;
	}
}

export default authReducer
