import './Input.scss';

const Input = ({id, type, placeholder, callBack}) => {
    const onChange = (e) => {
        callBack(e.target.value);
    }
    
    return (
        <input className='input' onChange={onChange} id={id} type={type} placeholder={placeholder} required />
    );
}

export default Input
