import $ from 'jquery';
import { BASE_URL_API } from 'assets/js/baseUrl';

export function getConnectionAndDirection() {
	return (dispatch, getState) => {
		$.ajax({
			type: 'POST',
			async: false,
			url: BASE_URL_API + '/workplace_screen/get_connection_and_direction',
			dataType: 'json',
			data: 'DATA=' + JSON.stringify({
				accessToken: getState().authReducer.accessToken,
			}),
			success: data => {
				dispatch(saveConnectionAndDirection(+data.work_direction, +data.master_id_connection))
			},
			error: error => {
				console.log(error);
			}
		});
	}
}

export const SAVE__CONNECTION_AND_DIRECTION = 'SAVE__CONNECTION_AND_DIRECTION';
function saveConnectionAndDirection(workDirection, masterIdConnection) {
	return {
		type: SAVE__CONNECTION_AND_DIRECTION,
		workDirection,
		masterIdConnection
	}
}

export function getDataForVideo() {
	return (dispatch, getState) => {
		$.ajax({
			type: 'POST',
			async: false,
			url: BASE_URL_API + '/workplace_screen/get_data_for_video',
			dataType: 'json',
			data: 'DATA=' + JSON.stringify({
				accessToken: getState().authReducer.accessToken,
				masterIdConnection: getState().appReducer.masterIdConnection
			}),
			success: data => {
				dispatch(saveDataForVideo(data.masterData, data.customerData, JSON.parse(data.videoList.video_list)))
			},
			error: error => {
				console.log(error);
			}
		});
	}
}

export const SAVE__DATA_FOR_VIDEO = 'SAVE__DATA_FOR_VIDEO';
function saveDataForVideo(masterData, customerData, videoList) {
	return {
		type: SAVE__DATA_FOR_VIDEO,
		masterData,
		customerData,
		videoList
	}
}
