import {
	SAVE__CONNECTION_AND_DIRECTION,
	SAVE__DATA_FOR_VIDEO,
} from './action';

const initialState = {
	workDirection: +localStorage.workDirection || 0,
	masterIdConnection: +localStorage.masterIdConnection || 0,

	masterData: localStorage.masterData ? JSON.parse(localStorage.masterData) : {},
	customerData: localStorage.customerData ? JSON.parse(localStorage.customerData) : {},

	videoList: localStorage.videoList ? JSON.parse(localStorage.videoList) : {},
}

const authReducer = (state = initialState, action) => {
	switch (action.type) {
		case SAVE__CONNECTION_AND_DIRECTION:
			localStorage.setItem('workDirection', action.workDirection);
			localStorage.setItem('masterIdConnection', action.masterIdConnection);
			return {
				...state,
				workDirection: action.workDirection,
				masterIdConnection: action.masterIdConnection,
			}

		case SAVE__DATA_FOR_VIDEO:
			localStorage.setItem('masterData', JSON.stringify(action.masterData));
			localStorage.setItem('customerData', JSON.stringify(action.customerData));
			localStorage.setItem('videoList', JSON.stringify(action.videoList));
			return {
				...state,
				masterData: action.masterData,
				customerData: action.customerData,
				videoList: action.videoList
			}

		default:
			return state;
	}
}

export default authReducer
