import * as React from 'react';
import ReactStopwatch from 'react-stopwatch';

class Stopwatch extends React.Component {
  shouldComponentUpdate() {
    return false;
  }
  
  render() {
    return (
      <ReactStopwatch
        seconds={0}
        minutes={0}
        hours={0}
        // limit="00:00:10"
        // onChange={({ hours, minutes, seconds }) => {
        //   // do something
        // }}
        onCallback={() => console.log('Finish')}
        render={({ formatted, hours, minutes, seconds }) => {
          return (
            <span>

              {formatted}

            </span>
          );
        }}
      />
    )
  }
};

export default Stopwatch
