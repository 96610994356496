import React, { useEffect, useState } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from 'react-router-dom';
import moment from 'moment';
import $ from 'jquery';
import { useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Login from 'pages/Login/Login';
import Connection from 'pages/Connection/Connection';
import Video from 'pages/Video/Video';
import { WS_URL } from 'assets/js/baseUrl';

console.log(moment().format('DD.MM.YYYY hh:mm:ss'))
function makePintServerBody(turnNum, services, allSum, sale) {
  const body = {
    daterecive: moment().format('DD.MM.YYYY hh:mm:ss'),
    ordernum: 0,
    finalsum: allSum,
    turnnum: turnNum,
    oderswrapper: forPrintNameOrder(services, sale)
  };

  return body;
}

function forPrintNameOrder(services, SALE) {
  let result = "";

  for (const key in services) {
    result += "<div class='brickservrecive'><div class='orders'>" + services[key].servName + "</div><div class='sumorders'>" + services[key].servPrice + "p</div></div>";
  }

  if (SALE) {
    result += "3 стрижка -50 %";
  }

  return result;
}

function printServer(turnNum, services, allSum, sale) {
  const body = makePintServerBody(turnNum, services, allSum, sale);

  $.ajax({
    type: "POST",
    // async: false,
    data: "jsondata=" + JSON.stringify(body),
    url: "http://localhost:3377",
    success: function (json) {
      console.log("Ответ принтсервера", json);
    }
  });
}

const App = () => {
  const { accessToken, workplaceNumber, workPointId } = useSelector(state => state.authReducer)

  const connect = () => {
    let ws = new WebSocket(WS_URL + '/terminal/' + workPointId);

    ws.onopen = () => {
      ws.send(JSON.stringify({ accessToken }));
    };

    ws.onmessage = (e) => {
      console.log('Message:', e.data);
      let data = JSON.parse(e.data);
      console.log(data)
      printServer(data.turnNum, data.services, data.allSum, data.sale)

      console.log('Message:', e.data);
    };

    ws.onclose = function (e) {
      console.log('Socket is closed. Reconnect will be attempted in 1 second.', e.reason);
      setTimeout(function () {
        connect();
      }, 1000);
    };

    ws.onerror = function (err) {
      console.error('Socket encountered error: ', err.message, 'Closing socket');
      ws.close();
    };

  }

  useEffect(() => {
    if (workPointId && workplaceNumber == '50001') {
      connect();
    }
  }, [workPointId, workplaceNumber])

  return (
    <Router basename='/'>
      <Switch>
        <Route exact path='/' component={Login} />
        <Route path='/connection' component={Connection} />
        <Route path='/video' component={Video} />
      </Switch>
      <ToastContainer/>
    </Router>
  )
}

export default App;
